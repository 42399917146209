import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/styles.css';

import BuildedForm from './BuildedForm';
import Footer from '../components/Footer';
import { StateContext } from '../App';

export default function Formulario({ history }) {
  const { tema, sections } = useContext(StateContext);
  const [site = 'loscabos'] = Object.values(useParams());

  const successfulSubmit = () => {
    history.push('/questions');
  };

  return (
    <div>
      <div
        className="formHero"
        style={{
          backgroundImage: `url("${tema.registrationImageEnglish}")`,
        }}
      ></div>
      <BuildedForm language="en" onSubmit={successfulSubmit} />

      <div className="legal">
        {sections.es && (
          <article
            className="footerNotice"
            dangerouslySetInnerHTML={{
              __html: sections.en.footer[site],
            }}
          ></article>
        )}
      </div>
      <Footer />
    </div>
  );
}
