import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Radio, message, Form, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/es';

import Navbar from '../components/Navbar';
import Loader from '../components/Loader';
import { getQuestions, saveUser } from '../firebase';
import { StateContext } from '../App';
import { storage } from '../storage';

import '../styles/styles.css';

const { Title, Text } = Typography;

const i18n = {
    en: {
      action: {
        successRoute: '/thankyou',
      },
      ui: {
        successMessage: 'Thank you',
        errorMessage: 'Something went wrong, please try again later',
        questionRequiredMessage: 'Please pick an option',
        questionText: 'Question',
        nextButton: 'Next question',
        sendButton: 'Send',
      },
    },
    es: {
      action: {
        successRoute: '/gracias',
      },
      ui: {
        successMessage: 'Gracias',
        errorMessage:
          'Algo salio mal, por favor intente de nuevo mas tarde',
        questionRequiredMessage: 'Por favor elige una opción',
        questionText: 'Pregunta',
        nextButton: 'Siguiente pregunta',
        sendButton: 'Enviar',
      },
    },
};

let totalTimeInterval;
let currentQuestionInterval;

const formatSeconds = (nSeconds) => {
  const minutes = Math.floor(nSeconds / 60);
  const seconds = nSeconds - minutes * 60;

  return [minutes, seconds]
    .map((n) => n.toString().padStart(2, '00'))
    .join(':');
};

const TimedQuestions = ({ history }) => {
  // State and effects

  const { tema, sections } = useContext(StateContext);
  const [site = 'loscabos'] = Object.values(useParams());
  const [timerStarted, setTimerStarted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] =
    useState(0);
  const [secondsPerQuestion, setSecondsPerQuestion] = useState(0);
  const [questionTime, setQuestionTime] = useState(0);
  const [answers, setAnswers] = useState({});
  const [answerstext, setAnswerstext] = useState({});
  const [user, setUser] = useState({});
  const [formTime, setFormTime] = useState(0);
  const [language, setLanguage] = useState(null);

  const startTime = () => {
    let tick;

    if (storage.getItem('formTime')) {
      tick = +storage.getItem('formTime');
    } else {
      tick = 0;
    }

    totalTimeInterval = setInterval(() => {
      tick++;
      storage.setItem('formTime', tick);
      setFormTime(tick);
    }, 1);
  };

  useEffect(() => {
    const currentUser = storage.getItem('currentUser');
    const currentLanguage = storage.getItem('language');

    if (currentUser && currentLanguage) {
      setUser(currentUser);
      setLanguage(currentLanguage);
    } else {
      history.push('/');
    }
  }, [history]);

  useEffect(() => {
    if (Object.keys(sections).length > 0 && language) {
      const secsPerQuestion = sections[language].secondsPerQuestion[site]
        ? +sections[language].secondsPerQuestion[site]
        : 10;
      setSecondsPerQuestion(secsPerQuestion);

      startTime();

      const questionsKey = `questions:${language}:${site}`;
      if (storage.getItem(questionsKey) !== null) {
        setQuestions(storage.getItem(questionsKey));

        storage.getItem('currentQuestionNumber')
          ? setCurrentQuestionNumber(
              +storage.getItem('currentQuestionNumber')
            )
          : setCurrentQuestionNumber(1);
      } else {
        getQuestions(language, site)
          .then((questions) => {
            const maxNumberOfQuestions = sections[language].questions[site]
              ? +sections[language].questions[site]
              : 12;
            const totalQuestions = Math.min(
              maxNumberOfQuestions,
              questions.length
            );

            const firebaseQuestions = [...questions];
            const randomizedQuestions = [];
            for (let i = 0; i < totalQuestions; i++) {
              let randomIndex = Math.floor(
                Math.random() * firebaseQuestions.length
              );
              randomizedQuestions.push(
                firebaseQuestions[randomIndex]
              );
              firebaseQuestions.splice(randomIndex, 1);
            }
            storage.setItem(questionsKey, randomizedQuestions);
            setQuestions(randomizedQuestions);
            setCurrentQuestionNumber(1);
          })
          .catch((e) => {
            message.error(i18n[language].ui.errorMessage);
          });
      }
    }

    return () => {
      clearInterval(totalTimeInterval);
    };
  }, [sections, language]);

  useEffect(() => {
    if (currentQuestionNumber > 0 && secondsPerQuestion > 0) {
      storage.setItem('currentQuestionNumber', currentQuestionNumber);

      const questionTimeInStorage = storage.getItem('questionTime');
      let tick = 0;

      if (
        !timerStarted &&
        questionTimeInStorage &&
        questionTimeInStorage < secondsPerQuestion
      ) {
        tick = +storage.getItem('questionTime');
      }

      setTimerStarted(true);
      setQuestionTime(tick);

      if (currentQuestionInterval) {
        clearInterval(currentQuestionInterval);
      }

      currentQuestionInterval = setInterval(() => {
        tick++;
        storage.setItem('questionTime', tick);

        if (tick > secondsPerQuestion) {
          handleSubmit();
        } else {
          setQuestionTime(tick);
        }
      }, 1000);
    }
    return () => {
      clearInterval(currentQuestionInterval);
    };
  }, [currentQuestionNumber, secondsPerQuestion]);

  // State validation

  if (
    !sections ||
    !language ||
    currentQuestionNumber === 0 ||
    Object.keys(sections).length === 0
  ) {
    return <Loader></Loader>;
  }

  // Logic and events

  const isLastQuestion = currentQuestionNumber === questions.length;
  const currentQuestion = questions[currentQuestionNumber - 1];

  const handleChange = (e) => {
    answers[currentQuestion.id] =
      currentQuestion.answers[e.target.value];
    answerstext[currentQuestion.question] = e.target.value;
    setAnswers(answers);
    setAnswerstext(answerstext);

    // handleSubmit();
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();

    if (isLastQuestion) {
      let rights = Object.values(answers);
      rights = rights.filter((e) => e === true).length;
      user['rights'] = rights;
      user['possible'] = sections[language].questions[site];
      user['time'] = formTime;
      user['date'] = moment(new Date()).format(
        'dddd D, MMMM YYYY HH:mm:ss.SSS'
      );
      user['answers'] = JSON.stringify(answerstext);

      saveUser(user)
        .then(() => {
          const questionsKey = `questions:${language}:${site}`;

          storage.removeItem(questionsKey);
          storage.removeItem('currentQuestionNumber');
          storage.removeItem('formTime');
          storage.removeItem('questionTime');
          storage.removeItem('currentUser');
          storage.setItem('userID', user.id);

          message.success(i18n[language].ui.successMessage);
          history.push(i18n[language].action.successRoute);
        })
        .catch((e) => {
          message.error(i18n[language].ui.errorMessage);
        });
    }

    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  const secondsRemaining = secondsPerQuestion - questionTime;
  const percentageRemaining = secondsRemaining / secondsPerQuestion;
  const secondsTextFormat =
    percentageRemaining <= 0.3
      ? 'danger'
      : percentageRemaining <= 0.6
      ? 'warning'
      : 'success';

  return (
    <div>
      <Navbar />
      <div className="preg">
        <div
          className="pregCol-1"
          style={{
            backgroundImage: `url("${tema.questionsImage}")`,
            backgroundRepeat: 'no-repeat',
          }}
        ></div>

        {currentQuestion && (
          <Form className="pregCol-2" onSubmit={handleSubmit}>
            <Title
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <span>
                {i18n[language].ui.questionText}
                &nbsp;({currentQuestionNumber}/{questions.length})
              </span>
              <Text type={secondsTextFormat}>
                {formatSeconds(secondsRemaining)}
              </Text>
            </Title>
            <div
              key={'question' + currentQuestionNumber}
              className="pregCol-2-1"
            >
              <h3>{currentQuestion.question}</h3>

              <Radio.Group
                id={currentQuestion.id}
                name={currentQuestion.id}
                onChange={handleChange}
              >
                {Object.keys(currentQuestion.answers).map(
                  (answer, index) => (
                    <Radio
                      key={index}
                      style={{ marginRight: '6px' }}
                      name={currentQuestion.id}
                      value={answer}
                    >
                      {answer}
                    </Radio>
                  )
                )}
              </Radio.Group>
            </div>

            {isLastQuestion ? (
              <button
                className="dufry-form-button primary"
                type="submit"
              >
                {i18n[language].ui.sendButton}
              </button>
            ) : (
              <button
                className="dufry-form-button primary"
                type="submit"
              >
                {i18n[language].ui.nextButton}
              </button>
            )}
          </Form>
        )}
      </div>
      <div className="legalQ">
        {sections[language] && (
          <article
            className="footerNotice"
            dangerouslySetInnerHTML={{
              __html: sections[language].footer[site],
            }}
          ></article>
        )}
      </div>
    </div>
  );
};

export default TimedQuestions;
