import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  fire,
  getUsers,
  getQuestions,
  removeQuestionsCollection,
  uploadQuestionsCollection
} from '../../firebase';
import {
  Table,
  Input,
  Icon,
  Form,
  Modal,
  Select,
  Button,
  Popconfirm,
  Upload,
  message
} from 'antd';
import * as XLSX from 'xlsx';

const { Option } = Select;

const QuestionsPage = ({ history }) => {
  const [site = 'loscabos'] = Object.values(useParams());
  const [logged, setLogged] = useState(false);
  const [fquestions, setQuestions] = useState([]);
  const [questionVisible, setQuestionVisible] = useState(false);
  const [question, setQuestion] = useState(undefined);
  const [questionsJSON, setQuestionsJSON] = useState([]);
  const [uploadVisible, setUploadVisible] = useState(false);

  useEffect(() => {
    fire.auth().onAuthStateChanged(u => {
      if (!u) {
        history.push(`/`);
      } else {
        getFUsers();
        getFQuestions();
        setLogged(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFUsers = () => {
    getUsers()
      .then(r => {})
      .catch(e => console.log(e));
  };

  const getFQuestions = () => {
    getQuestions(null, site)
      .then((qs) => {
        setQuestions(qs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const removeAllQuestions = () => {
    const hideLoadingMessage = message.loading('Eliminando preguntas', 0);
    removeQuestionsCollection(site)
      .then((res) => {
        getFQuestions();
        hideLoadingMessage();
        message.success('Se vació la colección de preguntas');
      })
      .catch((e) => {
        hideLoadingMessage();
        message.error(
          'Algo salio mal, por favor intentalo de nuevo mas tarde'
        );
        console.log(e);
      });
  };

  const setWorkbookFileToQuestionsJSON = (file, site='loscabos') => {
    const reader = new FileReader();
    reader.onload = function(e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets.Sheet1;
      const questions = XLSX.utils.sheet_to_json(worksheet);

      const questionsJSON = questions.map(q => {
        return {
          question: q.question,
          language: q.language,
          answers: {
            [`a: ${q['answer a']}`]: q.correct === 'a',
            [`b: ${q['answer b']}`]: q.correct === 'b',
            [`c: ${q['answer c']}`]: q.correct === 'c',
          },
          site,
        };
      });

      setQuestionsJSON(questionsJSON);
      setUploadVisible(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadQuestionsJSON = () => {
    setUploadVisible(false);
    const hideLoadingMessage = message.loading('Cargando preguntas', 0);

    uploadQuestionsCollection(questionsJSON)
      .then(res => {
        getFQuestions();
        hideLoadingMessage();
        message.success(
          `Se cargaron ${questionsJSON.length} elementos a la colección de preguntas`
        );
      })
      .catch(e => {
        getFQuestions();
        hideLoadingMessage();
        message.error('Algo salio mal, por favor intentalo de nuevo mas tarde');
        console.log(e);
      });
  };

  const handleEdit = obj => {
    setQuestion(obj);
    setQuestionVisible(true);
  };

  const tableColumns = [
    {
      title: 'Pregunta',
      dataIndex: 'question',
      key: 'question'
    },
    {
      title: 'Idioma',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'Respuestas ',
      dataIndex: 'answers',
      key: 'answers',
      render: row =>
        Object.keys(row).map((a, i) => (
          <p key={i} style={row[a] ? { color: 'green' } : { color: 'red' }}>
            {a}
          </p>
        ))
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      key: 'id',
      render: (id, obj) => (
        <span>
          <Icon type="edit" onClick={() => handleEdit(obj)} />
        </span>
      )
    }
  ];

  const uploadProps = {
    accept: '.xls,.xlsx',
    beforeUpload: file => {
      setWorkbookFileToQuestionsJSON(file, site);
    },
    showUploadList: false
  };

  if (!logged) return <p>You need to be logged</p>;
  return (
    <>
      <section>
        <article>
          <Upload {...uploadProps}>
            <Button>Cargar preguntas</Button>
          </Upload>

          <Popconfirm
            placement="right"
            title={`¿Seguro que desea eliminar todas las preguntas?`}
            onConfirm={removeAllQuestions}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={fquestions.length === 0} type="danger">
              Eliminar todas las preguntas
            </Button>
          </Popconfirm>
        </article>
        <Table rowKey="id" dataSource={fquestions} columns={tableColumns} />
      </section>

      {/* Confirm upload modal */}
      <Modal
        title="Upload confirmation"
        visible={uploadVisible}
        onOk={uploadQuestionsJSON}
        onCancel={() => setUploadVisible(false)}
      >
        <p>
          ¿Deseas cargar {questionsJSON.length} elementos a la colección de
          preguntas?
        </p>
      </Modal>

      {/* Display question modal */}
      {question && (
        <Modal
          title="Detalles de preguntas"
          visible={questionVisible}
          footer={null}
          onOk={() => {
            setQuestionVisible(false);
          }}
          onCancel={() => {
            setQuestionVisible(false);
          }}
        >
          <Form>
            <Form.Item label="Question">
              <Input value={question.question} name="question" />
            </Form.Item>

            <Form.Item label="Answers">
              <Input value={Object.keys(question.answers)[0]} name="answer" />
              <Input value={Object.keys(question.answers)[1]} name="answer" />
              <Input value={Object.keys(question.answers)[2]} name="answer" />
            </Form.Item>
            <Form.Item label="Idioma">
              <Select value={question.language} name="language">
                <Option value="en">Inglés</Option>
                <Option value="es">Español</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default QuestionsPage;
