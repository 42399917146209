import React, { useState } from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import { logIn } from '../../firebase';

const LoginPage = ({ history }) => {
  const [logUser, setLogUser] = useState({});

  const handleSubmit = e => {
    e.preventDefault();
    logIn(logUser)
      .then(r => {
        message.success('Welcome');
        history.push(`/admin`);
      })
      .catch(e => message.error(e.message));
  };
  const handleChange = e => {
    const obj = { ...logUser };
    obj[e.target.name] = e.target.value;
    setLogUser(obj);
  };

  return (
    <div className="login-container">
      <Form onSubmit={handleSubmit} className="login-form">
        <h2>Duty Free Admin</h2>
        <Form.Item>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="password"
            onChange={handleChange}
            type="password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
