import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '../App';
import '../styles/styles.css';
import dufryLosCabos from '../images/dufry-logo-web-loscabos.png';

export default function Navbar() {
  const { tema } = useContext(StateContext);
  return (
    <>
      <div className="nav">
        <div className="navLeft">
          <Link to="/">
            <img
              className="nflLogo"
              src={tema.logo}
              alt=""
            />
          </Link>
        </div>
        <div className="navRight">
          <img
            className="dufryLogo"
            src={dufryLosCabos}
            alt="dufry"
          />
        </div>
      </div>
    </>
  );
}
