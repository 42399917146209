import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  Checkbox,
  Icon,
  Input,
  Dropdown,
  Button,
  Form,
  Modal,
  Menu,
  message,
} from 'antd';
import { useParams } from 'react-router-dom';

import { storage } from '../storage';
import { StateContext } from '../App';
import countries from '../data/countries/data.json';
import { getUserByEmail, saveUser } from '../firebase';

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.payload.reduce((acc, input) => {
        const defaultValue = input.unique_key === 'terms' ? true : '';

        return {
          ...acc,
          [input.unique_key]: defaultValue,
        };
      }, {});

    case 'USER_FROM_STORAGE':
      return {
        ...state,
        ...action.payload,
      };

    case 'UPDATE':
      const { unique_key, value } = action.payload;
      return {
        ...state,
        [unique_key]: value,
      };

    default:
      return state;
  }
};

const i18n = {
  ui: {
    playButton: {
      en: 'PLAY',
      es: 'JUGAR',
    },
    messages: {
      loading: {
        en: 'One moment please',
        es: 'Un momento por favor',
      },
      success: {
        en: 'Loading game',
        es: 'Cargando juego',
      },
      error: {
        en: 'Something went wrong, try again later',
        es: 'Algo salio mal, por favor intente nuevamente luego',
      },
    },
  },
  validationErrors: {
    email: {
      en: 'Enter a valid e-mail address',
      es: 'Ingrese una dirección de correo electrónico valida',
    },
    phone: {
      en: 'At least 10 digits',
      es: 'Ingresa al menos 10 dígitos',
    },
    ticket: {
      en: 'This ticket has been registered',
      es: 'Este ticket ya fue registrado',
    },
    checkbox: {
      en: 'This field is required',
      es: 'Este campo es requerido',
    },
  },
};

const BuildedForm = ({ language, onSubmit }) => {
  const { form, sections } = useContext(StateContext);
  const [state, dispatch] = useReducer(reducer, {});
  const [site = 'loscabos'] = Object.values(useParams());
  const [formValidations, setFormValidations] = useState({});
  const [ticketModalVisible, setTicketModalVisible] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    getMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form) {
      dispatch({
        type: 'INITIAL_LOAD',
        payload: form,
      });

      const currentUser = storage.getItem('currentUser');
      if (currentUser) {
        dispatch({
          type: 'USER_FROM_STORAGE',
          payload: currentUser,
        });
      }
    }
  }, [form]);

  const validationHelpers = {
    email: {
      inputType: 'email',
      validationFunction: (key, value) => {
        value = value ? value : '';
        // eslint-disable-next-line no-useless-escape
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        setFormValidations({
          ...formValidations,
          [key]: !regex.test(value),
        });
      },
      validationError: i18n.validationErrors.email,
    },
    phone: {
      inputType: 'number',
      minLength: 10,
      validationFunction: (key, value) => {
        value = value ? value : '';

        setFormValidations({
          ...formValidations,
          [key]: value.length < 10,
        });
      },
      validationError: i18n.validationErrors.phone,
    },
    checkbox: {
      inputType: 'checkbox',
      validationFunction: async (key, value) => {
        setFormValidations({
          ...formValidations,
          [key]: !value,
        });
      },
      validationError: i18n.validationErrors.checkbox,
    },
  };

  const handleUserValidation = () => {
    getUserByEmail(state.email)
  }

  const handleChange = (e, type) => {
    const { name: key, value, checked } = e.target;

    dispatch({
      type: 'UPDATE',
      payload: {
        unique_key: key,
        value: value ?? checked,
      },
    });

    if (key === 'country') {
      dispatch({
        type: 'UPDATE',
        payload: {
          unique_key: 'phone_code',
          value: `${e.target.code}`,
        },
      });
    }

      if (validationHelpers[type]) {
        validationHelpers[type].validationFunction(
          key,
          value ?? checked
        );
      }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const hideLoadingMessage = message.loading(
      i18n.ui.messages.loading[language],
      0
    );
    const stateToSave = {
      ...state,
      phone: state.phone_code + state.phone,
      site
    };

    delete stateToSave.phone_code;
    saveUser(stateToSave)
      .then((response) => {
        hideLoadingMessage();
        message.success(i18n.ui.messages.success[language]);
        storage.setItem('currentUser', response);

        onSubmit();
      })
      .catch((e) => {
        hideLoadingMessage();
        message.error(i18n.ui.messages.error[language]);
        console.log(e);
      });
  };

  const ValidateForm = () => {
    const nonEmptyValues = Object.keys(state).some((key) => {
      return key !== 'ticket' && state[key] === '';
    });
    const allFormValidationsPassing = Object.values(
      formValidations
    ).some((invalid) => invalid);
    const validateTicketKey = state['ticket']
      ? state['ticket'].length < 10
      : false;

    return (
      nonEmptyValues || allFormValidationsPassing || validateTicketKey
    );
  };

  const toggleTicketModal = () => {
    setTicketModalVisible(!ticketModalVisible);
  };

  const getMenuItems = () => {
    const countriesList = Object.values(countries[language]);
    setMenuItems(
      <Menu>
        {countriesList.map((country) => (
          <Menu.Item
            key={country.key}
            onClick={(e) =>
              handleChange(
                {
                  target: {
                    name: 'country',
                    value: country.key,
                    code: country.phone_code,
                    checked: false,
                  },
                },
                'select'
              )
            }
          >
            {country.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Form className="formStyle" onSubmit={handleSubmit}>
        {form && (
          <>
            <article key="name">
              <Input
                className="input"
                type={
                  validationHelpers[form[0].type]
                    ? validationHelpers[form[0].type].inputType
                    : form[0].type
                }
                placeholder={form[0]['label_' + language]}
                minLength={
                  validationHelpers[form[0].type]
                    ? validationHelpers[form[0].type].minLength
                    : 5
                }
                name={form[0].unique_key}
                value={state[form[0].unique_key]}
                onChange={(e) => handleChange(e, form[0].type)}
                required
              />
            </article>
            {formValidations[form[0].unique_key] && (
              <p>
                {
                  validationHelpers[form[0].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            <article key="email">
              <Input
                className="input"
                type={
                  validationHelpers[form[1].type]
                    ? validationHelpers[form[1].type].inputType
                    : form[1].type
                }
                placeholder={form[1]['label_' + language]}
                minLength={
                  validationHelpers[form[1].type]
                    ? validationHelpers[form[1].type].minLength
                    : 5
                }
                name={form[1].unique_key}
                value={state[form[1].unique_key]}
                onChange={(e) => handleChange(e, form[1].type)}
                onBlur={handleUserValidation}
                required
              />
            </article>
            {formValidations[form[1].unique_key] && (
              <p>
                {
                  validationHelpers[form[1].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            <article key="country">
              <Dropdown
                overlay={menuItems}
                trigger={['click']}
                className="ant-dropdown-menu"
              >
                <Button>
                  <div className="ant-dropdown-menu-title">
                    {language === 'en'
                      ? 'Select your '
                      : 'Selecciona Tu '}
                    {form[2]['label_' + language]}{' '}
                    <Icon type="down" />
                  </div>
                </Button>
              </Dropdown>
              <span className="ant-dropdown-menu-selected">
                {state.country ?? ''}
              </span>
            </article>
            {formValidations[form[2].unique_key] && (
              <p>
                {
                  validationHelpers[form[2].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            <article key="phone" className="phone">
              <span className="phone-code">
                <Input
                  disabled={true}
                  className="input input-phone-code"
                  placeholder={
                    language === 'en'
                      ? 'Country Code'
                      : 'Código de País'
                  }
                  value={
                    state.phone_code
                      ? `+ (${state.phone_code})`
                      : null
                  }
                />
              </span>
              <span className="phone-number">
                <Input
                  className="input"
                  type={
                    validationHelpers[form[3].type]
                      ? validationHelpers[form[3].type].inputType
                      : form[3].type
                  }
                  placeholder={form[3]['label_' + language]}
                  minLength={
                    validationHelpers[form[3].type]
                      ? validationHelpers[form[3].type].minLength
                      : 5
                  }
                  name={form[3].unique_key}
                  value={state[form[3].unique_key]}
                  onChange={(e) => handleChange(e, form[3].type)}
                  required
                />
              </span>
            </article>
            {formValidations[form[3].unique_key] && (
              <p>
                {
                  validationHelpers[form[3].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            <article key="terms">
              <Checkbox
                className="input"
                name="terms"
                onChange={(e) => handleChange(e, 'checkbox')}
                defaultChecked={false}
                required
              >
                <a
                  href={
                    language === 'en'
                      ? '/en/terms-and-conditions'
                      : '/es/terminos-y-condiciones'
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: form[5]['label_' + language],
                    }}
                  />
                </a>
              </Checkbox>
            </article>
            {formValidations[form[5].unique_key] && (
              <p>
                {
                  validationHelpers[form[5].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            <article key="privacy">
              <Checkbox
                className="input"
                name="privacy"
                onChange={(e) => handleChange(e, 'checkbox')}
                defaultChecked={false}
                required
              >
                <a
                  href={
                    language === 'en'
                      ? '/en/privacy-policy'
                      : '/es/politica-de-privacidad'
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: form[6]['label_' + language],
                    }}
                  />
                </a>
              </Checkbox>
            </article>
            {formValidations[form[4].unique_key] && (
              <p>
                {
                  validationHelpers[form[4].type].validationError[
                    language
                  ]
                }
              </p>
            )}
            {formValidations[form[6].unique_key] && (
              <p>
                {
                  validationHelpers[form[6].type].validationError[
                    language
                  ]
                }
              </p>
            )}
          </>
        )}

        <Button
          disabled={ValidateForm()}
          htmlType="submit"
          className="button-form"
          type="primary"
        >
          {i18n.ui.playButton[language]}
        </Button>
      </Form>

      {sections[language] && (
        <Modal
          footer={false}
          centered={true}
          title={sections[language].ticketLink[site]}
          visible={ticketModalVisible}
          onOk={toggleTicketModal}
          onCancel={toggleTicketModal}
        >
          <article
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img
              src={sections[language].ticketImage[site]}
              alt="ticket example"
            />
          </article>
        </Modal>
      )}
    </>
  );
};

export default BuildedForm;
