import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/styles.css';
import Navbar from '../components/Navbar';
import { StateContext } from '../App';

export default function Privacypolicyes() {
  const { sections } = useContext(StateContext);
  const [site = 'loscabos'] = Object.values(useParams());

  return (
    <div>
      <Navbar />
      <section className="tyc">
        {sections.es && (
          <article
            dangerouslySetInnerHTML={{ __html: sections.es.terms[site] }}
          ></article>
        )}
      </section>
    </div>
  );
}
