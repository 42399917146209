import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId
};
// Initialize Firebase
export const fire = firebase.initializeApp(firebaseConfig);

const db = fire.firestore();
const storage = fire.storage();

const questions = db.collection('questions');
const users = db.collection('users');
const themes = db.collection('themes');
const sections = db.collection('sections');
const form = db.collection('form');

/* Sections */

export const getSections = () => {
  return sections
    .get()
    .then(snapShoot => {
      const docsArray = snapShoot.docs;
      const response = docsArray.reduce((acc, doc) => {
        return {
          ...acc,
          [doc.id]: doc.data()
        };
      }, {});

      return response;
    })
    .catch(e => e);
};

export const setSections = sectionsUpdate => {
  const sectionLanguages = Object.entries(sectionsUpdate).map(pair => {
    const [key, value] = pair;

    return sections.doc(key).set(value);
  });

  return Promise.all(sectionLanguages);
};

/* Form builder */

export const getFormFields = () => {
  return form
    .doc('fields')
    .get()
    .then(snapShoot => {
      return snapShoot.data();
    })
    .catch(e => e);
};

export const setFormFields = fieldsUpdate => {
  return form.doc('fields').set({
    data: fieldsUpdate
  });
};

/* Questions*/
export const saveQuestion = question => {
  if (!question.id) question['id'] = questions.doc().id;
  return questions
    .doc(question.id)
    .set(question)
    .then(q => q)
    .catch(e => e);
};

export const getQuestions = (language, site="loscabos") => {
  if (language) {
    return questions
      .where('site', '==', site)
      .where('language', '==', language)
      .get()
      .then(s => {
        const questions = [];
        s.forEach(doc =>
          questions.push({
            id: doc.id,
            ...doc.data()
          })
        );
        return questions;
      })
      .catch(e => console.log(e));
  } else {
    return questions
      .where('site', '==', site)
      .get()
      .then((s) => {
        const questions = [];
        s.forEach((doc) =>
          questions.push({
            id: doc.id,
            ...doc.data(),
          })
        );
        return questions;
      })
      .catch((e) => console.log(e));
  }
};

export const deleteQuestion = id => {
  questions
    .doc(id)
    .delete()
    .then(r => {
      return r;
    })
    .catch(e => {
      throw e;
    });
};

export const removeQuestionsCollection = (site = 'loscabos') => {
  return questions
    .where('site', '==', site)
    .get().then(querySnapshot => {
    const batch = db.batch();

    // For each doc, add a delete operation to the batch
    querySnapshot.forEach(doc => {
      batch.delete(doc.ref);
    });

    // Commit the batch
    return batch.commit();
  });
};

export const uploadQuestionsCollection = questionsJSON => {
  console.log({ questionsJSON });
  const promises = questionsJSON.map(q => questions.add(q));

  return Promise.all(promises);
};

/* Users / answers*/

export const saveUser = user => {
  if (!user.id) user['id'] = users.doc().id;
  return users
    .doc(user.id)
    .set(user)
    .then(q => user)
    .catch(e => e);
};

//get all user responses
export const getUsers = () => {
  return users
    .get()
    .then(s => {
      const users = [];
      s.forEach(doc => users.push(doc.data()));
      return users;
    })
    .catch(e => console.log(e));
};

export const getUserByTicket = ticket => {
  return users
    .where('ticket', '==', ticket)
    .get()
    .then(s => {
      const users = [];
      s.forEach(doc => users.push(doc.data()));
      return users[0];
    })
    .catch(e => console.log(e));
};

export const getUserByEmail = email => {
  return users
    .where('email', '==', email)
    .get()
    .then(s => {
      const users = [];
      s.forEach(doc => users.push(doc.data()));
      return users[0];
    })
    .catch(e => console.log(e));
};

export const getUserById = id => {
  return users
    .where('id', '==', id)
    .get()
    .then(s => {
      const users = [];
      s.forEach(doc => users.push(doc.data()));
      return users[0];
    })
    .catch(e => console.log(e));
};

/* Auth*/
export const logIn = user => {
  return fire
    .auth()
    .signInWithEmailAndPassword(user.email, user.password)
    .then(r => {
      return r.user;
    })
    .catch(e => {
      throw e;
    });
};

export const checkIfUser = () => {
  return fire.auth().onAuthStateChanged(u => {
    return u;
  });
};

export const logOut = () => {
  return fire.auth().signOut();
};

//Themes

export const uploadFile = (carpeta, nombre, file) => {
  const task = storage
    .ref(carpeta)
    .child(nombre)
    .put(file);
  return task.then(snap => {
    if (snap.state === 'success') {
      return storage
        .ref(carpeta)
        .child(nombre)
        .getDownloadURL()
        .then(link => link)
        .catch(e => e);
    } else {
      return snap;
    }
  });
};

export const saveTheme = (theme, site) => {
  if (!theme.id) theme['id'] = themes.doc().id;
  return themes
    .doc(theme.id)
    .set(theme)
    .then(q => theme)
    .catch(e => e);
};

export const getThemes = () => {
  return themes
    .get()
    .then(s => {
      const themes = [];
      s.forEach(doc => themes.push(doc.data()));
      return themes;
    })
    .catch(e => console.log(e));
};

export const getSingleTheme = id => {
  return themes
    .where('id', '==', id)
    .get()
    .then(s => {
      const themes = [];
      s.forEach(doc => themes.push(doc.data()));
      return themes[0];
    })
    .catch(e => console.log(e));
};
export const getActiveTheme = (site) => {
  return themes
    .where('active', '==', true)
    .where('site', '==', site)
    .get()
    .then(s => {
      const themes = [];
      s.forEach(doc => themes.push(doc.data()));
      return themes[0];
    })
    .catch(e => console.log(e));
};
